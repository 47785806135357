<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="4" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Quyết toán sửa chữa</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'e']" @shortkey="preview()" @click="preview()" class="md-raised md-view btn-add"><span>E</span>xport<md-tooltip>Export quyết toán (Ctrl + E)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>Quyết toán sửa chữa</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.invoiceCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.invoiceDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line-h"> 
                                            <label>Kính gửi: </label>
                                            <p>BGĐ Công ty PPL</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <table class="table-1" style="table-layout: auto !important;">
                                            <thead>
                                                <tr>
                                                    <th style="width:80px;">STT</th>
                                                    <th style="width:260px;">VẬT TƯ</th>
                                                    <th style="width:120px;">NGUỒN</th>
                                                    <th style="width:100px;">ĐVT</th>
                                                    <th style="width:100px;">S.LƯỢNG</th>
                                                    <th style="width:120px;">ĐƠN GIÁ</th>
                                                    <th style="width:100px;">VAT</th>
                                                    <th style="width:120px;">THÀNH TIỀN</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in details" :key="item.id">
                                                <tr>
                                                    <td class="stt"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                                    <td><span style="font-weight:bold;">{{item.key}}</span></td>
                                                    <td colspan="6"></td>
                                                </tr>
                                                <tr v-for="(obj, i) in item.list" :key="obj.id">
                                                    <td class="stt">{{i + 1}}</td>
                                                    <td><span style="padding-left: 20px;">{{obj.title}}</span></td>
                                                    <td class="stt">{{obj.sourceType.name}}</td>
                                                    <td class="stt">{{obj.unit}}</td>
                                                    <td class="stt">{{obj.quantity}}</td>
                                                    <td style="text-align: right;">{{formatNumber(obj.invoicePrice)}}</td>
                                                    <td style="text-align: right;">{{formatNumber(obj.invoiceVat)}}%</td>
                                                    <td style="text-align: right;">{{formatNumber(obj.invoiceAmount)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-bottom:80px; padding-top: 50px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="approve"> 
                                            <div v-for="item in entity.approveList" :key="item.id" class="approve-item">
                                                <div v-show="!item.isCommenter">
                                                    <div class="title">{{item.title}}</div>
                                                    <div class="approve-status">
                                                        <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 3" class="md-accent">Yêu cầu chỉnh sửa</md-chip>
                                                        <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                                    </div>
                                                    <div class="name">{{item.staff.fullName}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="action" >
                                    <md-button :disabled=" (entity.staffStatus > 1 || entity.isCommenter) && entity.status > 1" v-shortkey="['ctrl', 'y']" @shortkey="showConfirmEdit()" @click="showConfirmEdit()" class="md-raised md-second"><span>Y</span>êu cầu chỉnh sửa<md-tooltip>Yêu cầu chỉnh sửa dự toán (Ctrl + Y)</md-tooltip></md-button>
                                    <md-button :disabled=" (entity.staffStatus > 1 || entity.isCommenter) && entity.status > 1" v-shortkey="['ctrl', 'p']" @shortkey="showConfirmApprove()" @click="showConfirmApprove()" class="md-raised md-primary"><span>P</span>hê duyệt<md-tooltip>Phê duyệt (Ctrl + P)</md-tooltip></md-button>
                                    <md-button :disabled=" (entity.staffStatus > 1 || entity.isCommenter) && entity.status > 1" v-shortkey="['ctrl', 'k']" @shortkey="showConfirmReject()" @click="showConfirmReject()" class="md-raised md-accent"><span>K</span>hông phê duyệt<md-tooltip>Không phê duyệt (Ctrl + K)</md-tooltip></md-button>
                                    <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <md-dialog :md-active.sync="showApproveDialog">
            <md-dialog-title>Bạn có chắc chắn PHÊ DUYỆT?</md-dialog-title>
            <md-content>
                <div style="font-weight:bold;padding-bottom:10px;">PHÊ DUYỆT QUYẾT TOÁN SỬA CHỮA - Số: {{entity.invoiceCode}}</div>
                <md-field>
                    <md-textarea name="note" v-model="note" md-counter="500"></md-textarea>
                </md-field>
            </md-content>
            <md-dialog-actions>
                <md-button @click="showApproveDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="onApproved()">Phê duyệt</md-button>
            </md-dialog-actions>
        </md-dialog>
        
        <md-dialog :md-active.sync="showRejectDialog">
            <md-dialog-title>Bạn có chắc chắn KHÔNG PHÊ DUYỆT?</md-dialog-title>
            <md-content>
                <div style="font-weight:bold;padding-bottom:10px;">PHÊ DUYỆT QUYẾT TOÁN SỬA CHỮA - Số: {{entity.invoiceCode}}</div>
                <md-field :class="{'md-invalid': submitted && $v.note.$error }">
                    <md-textarea name="note" v-model="note" md-counter="500"></md-textarea>
                    <span class="md-error" v-if="submitted && !$v.note.required">Vui lòng nhập ý kiến </span>
                </md-field>
            </md-content>
            <md-dialog-actions>
                <md-button @click="showRejectDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="onReject()">Không phê duyệt</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showEditDialog">
            <md-dialog-title>Bạn có chắc chắn YÊU CẦU CHỈNH SỬA?</md-dialog-title>
            <md-content>
                <div style="font-weight:bold;padding-bottom:10px;">QUYẾT TOÁN SỬA CHỮA - Số: {{entity.invoiceCode}}</div>
                <md-field :class="{'md-invalid': submitted && note === '' }">
                    <md-textarea name="note" v-model="note" md-counter="350"></md-textarea>
                    <span class="md-error" v-if="submitted && note === ''">Vui lòng nhập ý kiến</span>
                </md-field>
            </md-content>
            <md-dialog-actions>
                <md-button @click="showEditDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="onEdit()">Gửi yêu cầu</md-button>
            </md-dialog-actions>
        </md-dialog>
        <preview ref="preview"/>
   </div>
</template>

<script>
    import repairInvoiceService from '../../../api/repairInvoiceService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import comment from '../../../components/Comment.vue';
    import preview from './Preview.vue';

    export default ({
        components: {
            comment,
            preview
        },
        metaInfo: {
            title: 'Phê duyệt quyết toán sửa chữa'
        },
        data() {
            return {
               title: 'Phê duyệt quyết toán sửa chữa',
               id: 0,
               showApproveDialog: false,
               showRejectDialog: false,
               showEditDialog: false,
               submitted: false,
               loadding: false,
               type: true,
               obj: {},
               note: '',
               comment: '',
               status: 0,
               details: [],
               entity: { id: 0, files: [], approveList: [], details: [], repairCompanyId: null, staffId: '', staff: {}, invoiceCode: '', invoiceDate: null, objectType: 4, status: 0, amount: 0, vat: 0, pay: 0 },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async preview () {
                this.$refs.preview.open(this.entity);
            },

            viewAttachFile(){
                let route = this.$router.resolve('/repair-invoice/view-attach/' + this.id);
                window.open(route.href, '_blank');
            },

            showConfirmApprove(){
                this.note = "";
                this.showApproveDialog = true;
            },

            showConfirmEdit(){
                this.comment = '';
                this.showEditDialog = true;
            },

            showConfirmReject(){
                this.note = '';
                this.showRejectDialog = true;
            },

            onCancel () {
                console.log('Cancel');
            },

            onApproved() {
                this.status = 2;
                this.submit();
            },

            onReject() {
                this.status = 4;
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.submit();
            },

            onEdit() {
                this.submitted = true;
                this.status = 3;
                if (this.note === '') {
                    return;
                }
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.submit();
            },

            submit(){
                this.setLoading(true);
                const data = { object: this.entity, status: this.status, note: this.note }
                repairInvoiceService.submitApprove(data).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/repair-invoice/approve-list');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.showRejectDialog = false });
            },
            
            getById(){
                this.setLoading(true);
                repairInvoiceService.getForApprove(this.id).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data == 'no-permission'){
                            this.$router.push('/access-denied');
                        }
                        else if(response.data == 'submit-approve'){
                            this.$router.push('/repair-invoice/view/' + this.id);
                        }
                        else{
                            this.entity = response.data;

                            const details = this.groupBy(this.entity.details);
                            this.details = [];

                            Object.keys(details).forEach((item, index) => {
                                const objItem = { key: item, list: details[item] };
                                details[index] = details[item];
                                delete details[item];
                                this.details.push(objItem);
                            });
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimate.estimateCode]: [...(groups[item.estimate.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },

            back(){
                this.$router.push('/repair-invoice/approve-list');
            }
        },
        validations: {
            note: { required },
        }
    })

</script>

<style scoped>
    .md-content {
        padding: 0 15px 0px 15px;
        width: 550px;
        height: 455px;
        max-width: 550px;
        max-height: 150px;
    }

    .dialog-content {
        height: 150px;
    }
    .action {
        position: sticky;
        bottom: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
    }
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 14px;
    }
    .right {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 30px;
    }
    .right .item {
        display: block;
        position: relative;
        font-size: 14px;
    }
    .right .md-checkbox {
        margin: 0 !important;
    }
    .label {
        font-weight: bold;
    }
    .line-h {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .line-h label {
        font-weight: bold;
        font-size: 18px;
        padding-right: 10px;
        text-decoration: underline;
    }
    .line-h p {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .line p {
        font-size: 14px;
        line-height: 1.2 rem;
    }
    b {
        font-weight: bold;
    }
    .content {
        font-style: italic;
        font-weight: bold;
    }
    .line-f {
        padding: 30px 0;
        display: flex;
        align-items: center;
    }
    .line-f .title {
        font-size: 14px;
        padding-right: 30px;
    }
    .line-f .option {
        display: flex;
    }
    .line-f .option .option-item {
        padding-right: 50px;
        font-size: 17px;
    }
    .approve {
        display: flex;
        justify-content: space-around;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
</style>
